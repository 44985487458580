import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr'
import { english } from 'flatpickr/dist/l10n/default'

const moment = require('moment')

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'
// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      monthSelectorType: 'static',
      minuteIncrement: 1,
      locale: language
    }
  }
}
