import { Controller } from 'stimulus'
import toastr from 'toastr'

export default class extends Controller {
  static targets = ['content'];

  connect () {
    this.uiToastrNotification()
  }

  disconnect () {
    toastr.remove()
  }

  uiToastrNotification () {
    const type = this.contentTarget.getAttribute('data-type')
    const message = this.contentTarget.getAttribute('data-message')
    const title = this.contentTarget.getAttribute('data-title')
    toastr.options = {
      closeButton: this.contentTarget.getAttribute('data-closeButton') || true,
      debug: this.contentTarget.getAttribute('data-ebug') || false,
      newestOnTop: this.contentTarget.getAttribute('data-newestOnTop') || true,
      progressBar: this.contentTarget.getAttribute('data-progressBar') || false,
      positionClass: this.contentTarget.getAttribute('data-positionClass') || 'toast-top-right',
      preventDuplicates: this.contentTarget.getAttribute('data-preventDuplicates') || true,
      showDuration: this.contentTarget.getAttribute('data-showDuration') || '300',
      hideDuration: this.contentTarget.getAttribute('data-hideDuration') || '1000',
      timeOut: this.contentTarget.getAttribute('timeOut') || '5000',
      extendedTimeOut: this.contentTarget.getAttribute('data-extendedTimeout') || '1000',
      showEasing: this.contentTarget.getAttribute('data-showEasing') || 'swing',
      hideEasing: this.contentTarget.getAttribute('data-hideEasing') || 'linear',
      showMethod: this.contentTarget.getAttribute('data-showMethod') || 'fadeIn',
      hideMethod: this.contentTarget.getAttribute('data-hideMethod') || 'fadeOut'
    }
    toastr[type](message, title)
  }
}
