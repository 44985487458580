import { Controller } from 'stimulus'
import daterangepicker from 'bootstrap-daterangepicker/daterangepicker'

export default class extends Controller {
  static targets = ['picker'];

  initialize () {
    this.initializePicker()

    $(this.pickerTarget).on('apply.daterangepicker', function (event, picker) {
      const myData = {
        start_datetime: picker.startDate.format(),
        end_datetime: picker.endDate.format()
      }

      Chartkick.eachChart(function (chart) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        const newDatasourceUrl = `${locationNodePathName}?${new URLSearchParams(
          myData
        ).toString()}`

        chart.updateData(newDatasourceUrl)
      })

      const categoryCharts = document.getElementsByClassName('category-chart')
      for (let i = 0; i < categoryCharts.length; i++) {
        const chart = categoryCharts[i]
        const locationNodePathName = chart
          .getAttribute('data-category-chart-datasource-url')
          .split('?')[0]
        const newDatasourceUrl = `${locationNodePathName}?${new URLSearchParams(
          myData
        ).toString()}`

        chart.setAttribute(
          'data-category-chart-datasource-url',
          newDatasourceUrl
        )
        chart.dispatchEvent(
          new Event('datasource-url-changed', { bubbles: true })
        )
      }
    })
  }

  initializePicker () {
    const locale = this.data.get('locale') || 'default'
    const startDate = this.data.get('startDate')
    const endDate = this.data.get('endDate')
    const maxDate = this.data.get('maxDate')
    const minDate = this.data.get('minDate')
    const controller = this

    $(this.pickerTarget).daterangepicker({
      timePicker: true,
      timePicker24Hour: true,
      startDate: startDate,
      endDate: endDate,
      minDate: minDate,
      maxDate: maxDate,
      locale: locales[locale]
    })
  }
}

const locales = {
  el: {
    format: 'DD-MM-YYYY HH:mm',
    separator: ' έως ',
    applyLabel: 'Εφαρμογή',
    cancelLabel: 'Ακύρωση',
    fromLabel: 'Από',
    toLabel: 'Έως',
    weekLabel: 'Ε',
    daysOfWeek: ['Κυρ.', 'Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ'],
    monthNames: [
      'Ιανουάριος',
      'Φεβρουάριος',
      'Μάρτιος',
      'Aπρίλιος',
      'Μάιος',
      'Ιούνιος',
      'Ιούλιος',
      'Αύγουστος',
      'Σεπτέμβριος',
      'Οκτώβριος',
      'Νοέμβριος',
      'Δεκέμβριος'
    ],
    firstDay: 1
  },
  en: {
    format: 'DD-MM-YYYY HH:mm'
  },
  default: {
    format: 'DD-MM-YYYY HH:mm'
  }
}
