import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'
import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin.js'

const moment = require('moment')

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    const plugins = []

    plugins.push(new rangePlugin({ input: '#end-time-picker' }))

    this.config = {
      mode: 'range',
      monthSelectorType: 'static',
      enableTime: true,
      dateFormat: 'd-m-Y H:i',
      locale: language,
      plugins: plugins,
      time_24hr: true
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  close (selectedDates, dateStr, instance) {
    console.log(selectedDates)
    return
    const updateChartID = this.element.getAttribute('data-chart-id')

    if (selectedDates.length < 2) {
      return
    }

    const myData = {
      start_datetime: selectedDates[0],
      end_datetime: selectedDates[1]
    }

    if (updateChartID != null) {
      const chart = Chartkick.charts[updateChartID]
      if (chart != null) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        chart.updateData(
          `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
        )
      } else {
        console.log(
          `can not find chart with the spacific id: ${updateChartID}`
        )
      }
    } else {
      Chartkick.eachChart(function (chart) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        chart.updateData(
          `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
        )
      })
    }
  }
}
