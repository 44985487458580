import { Controller } from 'stimulus'

const Core = require('@uppy/core') // see below for the full list of locales
const FileInput = require('@uppy/file-input') // see below for the full list of locales
const ThumbnailGenerator = require('@uppy/thumbnail-generator') // see below for the full list of locales
const XHRUpload = require('@uppy/xhr-upload') // see below for the full list of locales
const Greek = require('@uppy/locales/lib/el_GR') // see below for the full list of locales

export default class extends Controller {
  static targets = ['preview', 'fileInput', 'result'];

  connect () {
    // keep uppy as an instance variable in order to reset state before disconnecting controller
    this.uppy = this.initializeUppy()

    // keep original image preview src to prevent turbolinks from caching a reference to thumbnail generator file blob
    this.imagePreviewOriginalSrc = this.previewTarget.src
  }

  disconnect () {
    // reset uppy so that after turbolinks restore from cache there is only one instance of it
    this.resetUppy()
  }

  initializeUppy = () => {
    const fileUploadArea = this.fileInputTarget
    const imagePreview = this.previewTarget
    const uppy = this.fileUpload(fileUploadArea)

    const appLocale = document.body.getAttribute('data-locale')
    const uppyLocale = appLocale === 'el' ? Greek : null

    uppy
      .use(FileInput, {
        target: fileUploadArea,
        locale: uppyLocale
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 100
      })

    uppy.on('upload-success', (file, response) => {
      const fileData = this.uploadedFileData(
        file,
        response,
        this.fileInputTarget
      )

      // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
      this.resultTarget.value = fileData
      this.uppy.reset()
    })

    uppy.on('thumbnail:generated', (file, preview) => {
      imagePreview.src = preview
    })

    return uppy
  };

  resetUppy = () => {
    this.previewTarget.src = this.imagePreviewOriginalSrc
    this.uppy.close()
  };

  fileUpload = fileInput => {
    const uppy = Core({
      id: fileInput.id,
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
        allowedFileTypes: fileInput.dataset.acceptedFormats.split(',')
      }
    })
    uppy.use(XHRUpload, {
      endpoint: '/upload' // Shrine's upload endpoint
    })
    return uppy
  };

  uploadedFileData = (file, response, fileInput) => {
    return JSON.stringify(response.body)
  };
}
