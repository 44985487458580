import { Controller } from 'stimulus'
import 'moment/locale/el'

import Chart from 'chart.js'

export default class extends Controller {
  static targets = ['canvas'];

  connect () {
    this.chart = this.initializeCategoryChart()
    this.updateChartData()
  }

  disconnect () {
    this.chart
  }

  baseConfig = (titles, timeOptions) => {
    return {
      type: 'line',
      data: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: titles.chartTitle,
          fontSize: 20,
          fontColor: '#333'
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              },
              type: 'time',
              time: {
                tooltipFormat: timeOptions.tooltipFormat,
                unit: 'second',
                unitStepSize: 10
              },
              scaleLabel: {
                display: true,
                labelString: titles.xTitle,
                fontSize: 16,
                fontColor: '#333'
              }
            }
          ],
          yAxes: [
            {
              type: 'category',
              position: 'left',
              display: true,
              scaleLabel: {
                display: true,
                labelString: titles.yTitle,
                fontSize: 16,
                fontColor: '#333'
              },
              ticks: {
                reverse: true
              }
            }
          ]
        }
      }
    }
  };

  initializeCategoryChart = () => {
    const title = this.data.get('title') || ''
    const xTitle = this.data.get('xTitle')
    const yTitle = this.data.get('yTitle')
    const tooltipFormat = this.data.get('tooltipFormat') || 'll HH:mm:ss'

    const chart = new Chart(
      this.canvasTarget,
      this.baseConfig(
        { chartTitle: title, xTitle: xTitle, yTitle: yTitle },
        { tooltipFormat: tooltipFormat }
      )
    )
    return chart
  };

  updateChartData = () => {
    const controller = this
    const url = this.getDatasourceURL()
    Rails.ajax({
      url: url,
      type: 'GET',
      success: function (data) {
        const chartData = controller.formatJSONData(data)

        try {
          controller.chart.data = chartData
          controller.chart.update()
        } catch (err) {
          controller.chartError('Malformed data')
          throw err
        }
      },
      error: function (data) {
        controller.chartError(data.error, true)
      }
    })
  };

  formatJSONData = data => {
    const chartData = {}
    chartData.yLabels = data.yLabels
    chartData.yLabels.unshift('')
    chartData.datasets = data.datasets.map(function (dataset) {
      return {
        label: dataset.label,
        data: dataset.data,
        fill: false,
        borderColor: 'red',
        backgroundColor: 'red',
        showLine: false,
        pointRadius: 4
      }
    })
    return chartData
  };

  getDatasourceURL = () => {
    return this.data.get('datasourceUrl')
  };

  chartError = message => {
    message = 'Error Loading Chart Data: ' + message
    this.element.textContent = message
    this.element.style.color = '#ff0000'
    this.element.style.textAlign = 'center'
    this.element.style.lineHeight = '300px'
  };
}
