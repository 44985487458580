import { Controller } from 'stimulus'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['link']

  connect () {
    this.confirmed = false
  }

  disconnect () {
    Swal.close()
  }

  userConfirmed (result) {
    if (result.value) {
      // User clicked confirm button
      this.confirmed = true
      this.linkTarget.click()
    }
  }

  click (event) {
    if (this.confirmed === false) {
      event.preventDefault()
      event.stopPropagation()
      this.showConfirmationAlert()
      return false
    }
  }

  showConfirmationAlert () {
    const message = this.linkTarget.getAttribute('data-confirm-swal')
    const confirmButtonText = this.linkTarget.getAttribute('data-confirm-button-text') || 'Yes'
    const cancelButtonText = this.linkTarget.getAttribute('data-cancel-button-text') || 'Cancel'
    const text = this.linkTarget.getAttribute('data-text')

    Swal.fire({
      title: message || 'Are you sure?',
      text: text || '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    }).then((result) => {
      this.userConfirmed(result)
    })
  }

  handleSuccess (event) {
  }

  handleFailure (event) {
  }
}
