import 'select2'

$(document).on('turbolinks:load', function (e) {
  const appLocale = document.body.getAttribute('data-locale')
  moment.locale(appLocale)

  $('select:not(.custom-select)').select2({
    theme: 'bootstrap4'
  })
  $('[data-toggle="popover"]').popover()
})
